// extracted by mini-css-extract-plugin
export var productPage = "ProductPage-module--productPage--roUAG";
export var headerContainer = "ProductPage-module--headerContainer--14HjJ";
export var pageWrapper = "ProductPage-module--pageWrapper--3J22P";
export var content = "ProductPage-module--content--2AHW5";
export var section = "ProductPage-module--section--1boD3";
export var product = "ProductPage-module--product--tukQd";
export var productContainer = "ProductPage-module--productContainer--1dhbn";
export var goBackArrow = "ProductPage-module--goBackArrow--2E0kH";
export var productImage = "ProductPage-module--productImage--3xWn1";
export var logInContainer = "ProductPage-module--logInContainer--3lkjp";
export var logInContainerDesktop = "ProductPage-module--logInContainerDesktop--cslc0";
export var heartIcon = "ProductPage-module--heartIcon--25ZwC";
export var alreadyMember = "ProductPage-module--alreadyMember--2kd1u";
export var alreadyMemberLink = "ProductPage-module--alreadyMemberLink--3hCbi";
export var productInfoContainer = "ProductPage-module--productInfoContainer--2amtB";
export var productTitle = "ProductPage-module--productTitle--31Rim";
export var priceContainer = "ProductPage-module--priceContainer--1WUxc";
export var box = "ProductPage-module--box--nyK1F";
export var price = "ProductPage-module--price--CV36d";
export var priceRed = "ProductPage-module--priceRed--5F1Q9";
export var priceText = "ProductPage-module--priceText--nk0Hv";
export var priceTextRed = "ProductPage-module--priceTextRed--_84HZ";
export var sizeSelect = "ProductPage-module--sizeSelect--aL77X";
export var sizeSelectorContainer = "ProductPage-module--sizeSelectorContainer--2XUbt";
export var sizeChartLink = "ProductPage-module--sizeChartLink--2pkZS";
export var bodyOverlay = "ProductPage-module--bodyOverlay--9DbwO";
export var popUpContainer = "ProductPage-module--popUpContainer--3ClQP";
export var closeIcon = "ProductPage-module--closeIcon--3bPhM";
export var sizeChart = "ProductPage-module--sizeChart--2Iy-h";
export var buttonContainer = "ProductPage-module--buttonContainer--25dHA";
export var amazonBlock = "ProductPage-module--amazonBlock--zdVYf";
export var amazonIcon = "ProductPage-module--amazonIcon--2h_Br";
export var productTerms = "ProductPage-module--productTerms--yrZV_";
export var productTermsTitle = "ProductPage-module--productTermsTitle--13J-R";
export var moreSection = "ProductPage-module--moreSection--_95g6";
export var moreIcon = "ProductPage-module--moreIcon--16MX6";
export var moreBlock = "ProductPage-module--moreBlock--3rNDb";
export var moreContent = "ProductPage-module--moreContent--ngkGY";
export var moreTitle = "ProductPage-module--moreTitle--SUIrl";
export var moreTitle2 = "ProductPage-module--moreTitle2--2Vv9Z";
export var moreText = "ProductPage-module--moreText--JwfRe";
export var moreTextBold = "ProductPage-module--moreTextBold--GdaJW";
export var moreEmail = "ProductPage-module--moreEmail--3iC6K";
export var relatedSection = "ProductPage-module--relatedSection--2wWV3";
export var relatedSectionTitle = "ProductPage-module--relatedSectionTitle--2fQC8";
export var collectionSlider = "ProductPage-module--collectionSlider--2JkyO";
export var collectionSliderMobile = "ProductPage-module--collectionSliderMobile--2M42_";
export var customersSlider = "ProductPage-module--customersSlider--IqQz0";
export var prevSlide = "ProductPage-module--prevSlide--3X0Iz";
export var nextSlide = "ProductPage-module--nextSlide--34VuD";
export var prevSlideReview = "ProductPage-module--prevSlideReview--sChS4";
export var nextSlideReview = "ProductPage-module--nextSlideReview--DObRj";
export var collectionItem = "ProductPage-module--collectionItem--3GB5p";
export var productCard = "ProductPage-module--productCard--cmFR6";
export var productImg = "ProductPage-module--productImg--bQG4P";
export var productInfo = "ProductPage-module--productInfo--mJvk3";
export var productNameSlider = "ProductPage-module--productNameSlider--YybFn";
export var productMemberPrice = "ProductPage-module--productMemberPrice--Y42Io";
export var pointsSection = "ProductPage-module--pointsSection--wr0tr";
export var pointsBlock = "ProductPage-module--pointsBlock--3Zpuq";
export var pointsMobileTitle = "ProductPage-module--pointsMobileTitle--y5Lgp";
export var pointsItem = "ProductPage-module--pointsItem--1U_UY";
export var pointsHeader = "ProductPage-module--pointsHeader--_S6-N";
export var pointsIcon = "ProductPage-module--pointsIcon--243fD";
export var pointsText = "ProductPage-module--pointsText--LRe8p";
export var customersSliderTitle = "ProductPage-module--customersSliderTitle--354y_";
export var customersItem = "ProductPage-module--customersItem--1ObSb";
export var customersItemWrapper = "ProductPage-module--customersItemWrapper--QlVce";
export var customersData = "ProductPage-module--customersData--2tm30";
export var customersVerify = "ProductPage-module--customersVerify--2knPe";
export var customersVerifyIcon = "ProductPage-module--customersVerifyIcon--36qof";
export var customersWherefrom = "ProductPage-module--customersWherefrom--W1mJh";
export var customersRating = "ProductPage-module--customersRating--3o-H_";
export var customersTitle = "ProductPage-module--customersTitle--y-TB0";
export var customersReview = "ProductPage-module--customersReview--315Cn";
export var customersSection = "ProductPage-module--customersSection--1Xu_v";
export var customersList = "ProductPage-module--customersList--uGzXP";
export var foollowUsContainer = "ProductPage-module--foollowUsContainer--2FrSR";
export var footerContainer = "ProductPage-module--footerContainer--2bj8n";