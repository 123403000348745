import React, { useState, useEffect, useRef } from 'react'
import { Link } from "gatsby";
import { BrowserView, MobileView } from 'react-device-detect'
import Slider from 'react-slick'
import { connect } from 'react-redux'
import ProgressiveImage from 'react-progressive-image'
import { trackingGA } from '@merchx-v2/helpers'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { navigate } from 'utils/navigate'
import { Button, Header, FacebookPixels, Footer, Notification, Select, SampleArrow, FollowUs, SEO } from 'components'
import arrowGoBack from 'assets/arrow-go-back.svg'
import amazonIcon from 'assets/amazon-icon.svg'
import guaranteeIcon from 'assets/guarantee-icon.svg'
import madeIcon from 'assets/made-icon.svg'
import priceIcon from 'assets/price-icon.svg'
import usaIcon from 'assets/usa-icon.svg'
import chartImage from 'assets/chart.jpg'
import closeIcon from 'assets/close.svg'
import rating from 'assets/rating.svg'
import redCheck from 'assets/red-check.svg'
import nextArrow from 'assets/next-arrow.png'
import prevArrow from 'assets/prev-arrow.png'
import plusIcon from 'assets/plus.svg'
import heartIcon from 'assets/heartIcon.svg'
import { convertToUSD } from '@merchx-v2/helpers'

import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'

import { selectMenu, selectPrices, setMenuCollection, selectProducts } from 'state/HomePage/reducer'

import { actionCreators as loginActions, selectors as loginSelectors } from 'state/LoginPage/reducer'

import * as styles from './ProductPage.module.scss'
import * as actions from 'state/ProductPage/actions'
import { actionCreators, selectors } from 'state/ProductPage/reducer'


// This page is using special library to use mobile and browser detection to prevent render not needed sliders!!!!
const ProductPage = props => {
  const {
    loginState,
    productInfo,
    productInfo: { selectedProductPrice: productPrice },
    setMenuCollection,
    prices,
    products,
    setPageAfterLogin,
    setTshirtSize,
    pageContext
  } = props

  const { productId, seoProduct } = pageContext

  const { shared, landing } = useSiteMetadata()

  const campaignName = landing.CAMPAIGN.name
  const supportEmail = shared.SUPPORT_EMAIL

  const [isSizingChartOpened, setIsSizingChartOpened] = useState(false)
  const [isMoreInfoOpened, setIsMoreInfoOpened] = useState(false)
  const [notification, showNotification] = useState()
  const [isShowErrors, setIsShowErrors] = useState(false)
  const [collectionName, setCollectionName] = useState(null)
  const [collectionProducts, setCollectionProducts] = useState([])
  const [productToShowMobile, setProductToShowMobile] = useState(4)
  const [selectedProductId, setSelectedProductId] = useState()
  const [slidesToShow, setSlidesToShow] = useState(8)

  const moreRef = useRef()

  const chartRef = useRef()
  const handleChartOutside = e => {
    if (!chartRef.current.contains(e.target)) {
      setIsSizingChartOpened(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    trackingGA()
    setPageAfterLogin(props.location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [selectedProductId])

  useEffect(() => {
    showNotification(productInfo.UIState.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo?.UIState.error])

  useEffect(() => {
    const sizesOrder = ['SM', 'MD', 'LG', 'XL', '2XL']

    const productPrices = prices
      .filter(item => (item.hasCustomSeo && item.seoUrl === productId) || item.productId === +productId)
      .sort((priceA, priceB) => {
        const sizeA = priceA.attributes.find(item => item.attribute === 'size')
        const sizeB = priceB.attributes.find(item => item.attribute === 'size')

        return sizesOrder.indexOf(sizeA?.value || '2XL') - sizesOrder.indexOf(sizeB?.value || '2XL')
      })

    const productPrice = productPrices[0]
    if (productPrice) {
      setCollectionName(productPrice?.attributes.find(collection => collection.attribute === 'collection').value)
      props.setProductPrice({ productPrice, prices })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, prices])

  useEffect(() => {
    if (collectionName) {
      setMenuCollection(collectionName)
      setCollectionProducts(products.slice(0, slidesToShow))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, products, slidesToShow])



  const handleMoreOutside = e => {
    if (!moreRef.current.contains(e.target)) {
      setIsMoreInfoOpened(false)
      document.body.style.overflow = 'visible'
    }
  }

  const handleShowMoreClicked = value => {
    document.body.style.overflow = value ? 'hidden' : 'visible'
    setIsMoreInfoOpened(value)
  }

  const handleBuyProductClicked = async () => {
    setIsShowErrors(true)
    if (productInfo.isValid) {
      const { payload } = await props.addProductToCartAsync({ landingId: landing.LANDING_ID })
      if (payload?.isSaved) {
        navigate('/shipping')
      }
    }
  }

  const handleSliderSwipe = (slidersLength, initialLength) => {
    const newSlidesLength = (initialLength * 2) + slidersLength
    if (slidesToShow < newSlidesLength) {
      setSlidesToShow(newSlidesLength)
    }
  }

  const mobileCustomerSliderSettingsForReviews = {
    // ----------------- mobile customers slider settings
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: typeof window !== 'undefined' && window.innerWidth < 720 ? 1 : 2,
    slidesToScroll: typeof window !== 'undefined' && window.innerWidth < 720 ? 1 : 2,
    nextArrow: <SampleArrow internalClassName={styles.nextSlideReview} img={nextArrow} />,
    prevArrow: <SampleArrow internalClassName={styles.prevSlideReview} img={prevArrow} />
  }

  const collectionSliderSettings = {
    // ----------------- products slider settings
    dots: true,
    infinite: false,
    speed: 500,
    afterChange: slidersLength => handleSliderSwipe(slidersLength, 4),
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleArrow internalClassName={styles.nextSlide} img={nextArrow} />,
    prevArrow: <SampleArrow internalClassName={styles.prevSlide} img={prevArrow} />
  }

  const mobileCollectionSliderSettings = {
    // ----------------- mobile products slider settings
    dots: true,
    infinite: false,
    speed: 500,
    afterChange: slidersLength => handleSliderSwipe(slidersLength, 2),
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <SampleArrow internalClassName={styles.nextSlide} img={nextArrow} />,
    prevArrow: <SampleArrow internalClassName={styles.prevSlide} img={prevArrow} />,
    swipeEvent: () => setProductToShowMobile(productToShowMobile + 4)
  }

  const retailPrice = ((productPrice?.price || 0) / 100).toFixed(2)
  const memberPrice = ((productPrice?.memberPrice || 0) / 100).toFixed(2)

  return (
    <div className={styles.productPage}>
      <SEO
        title={seoProduct?.hasCustomSeo ? seoProduct?.seoTitle : null}
        description={seoProduct?.hasCustomSeo ? seoProduct?.seoDescription : null}
        keywords={seoProduct?.hasCustomSeo ? seoProduct?.seoKeywords : null}
        image={seoProduct?.hasCustomSeo ? seoProduct?.originalImageUrl : null}
        pathname={`/products/${productId}`}
      />
      <FacebookPixels id={shared.FACEBOOK_PIXEL_ID} event='Search' />
      <div className={styles.headerContainer} id='header'>
        <Header isNeedDescription={true} />
      </div>
      <div className={styles.pageWrapper}>
        <div className={styles.content}>
          <div className={styles.section}>
            <div className={styles.product}>
              <div className={styles.productContainer}>
                <img className={styles.goBackArrow} src={arrowGoBack} onClick={() => navigate('/')} alt='-' />
                <ProgressiveImage src={productPrice?.originalImageUrl} placeholder={productPrice?.previewImageUrl}>
                  {src => <img src={src} alt={productPrice?.name} className={styles.productImage} />}
                </ProgressiveImage>
              </div>
            </div>

            {loginState.email === '' && (
              <div className={styles.logInContainer}>
                <img className={styles.heartIcon} src={heartIcon} alt='' />
                <p className={styles.alreadyMember}>
                  Already a member?{' '}
                  <Link to='/login' className={styles.alreadyMemberLink}>
                    Log In
                  </Link>{' '}
                  or{' '}
                  <Link to='/register' className={styles.alreadyMemberLink}>
                    Sign Up
                  </Link>
                </p>
              </div>
            )}

            <div className={styles.productInfoContainer}>
              {loginState.email === '' && (
                <div className={styles.logInContainerDesktop}>
                  <img className={styles.heartIcon} src={heartIcon} alt='' />
                  <p className={styles.alreadyMember}>
                    Already a member?{' '}
                    <Link to='/login' className={styles.alreadyMemberLink}>
                      Log In
                    </Link>{' '}
                    or{' '}
                    <Link to='/register' className={styles.alreadyMemberLink}>
                      Sign Up
                    </Link>
                  </p>
                </div>
              )}

              <div className={styles.productTitle}>{productPrice?.productDisplayName}</div>

              <div className={styles.priceContainer}>
                <div className={styles.box}>
                  <div className={styles.price}>${retailPrice}</div>
                  <span className={styles.priceText}>Non Member Price</span>
                </div>
                <div className={styles.box}>
                  <div className={styles.priceRed}>${memberPrice}</div>
                  <span className={styles.priceTextRed}>VIP Member Price</span>
                </div>
              </div>

              <div className={styles.sizeSelect}>
                <div className={styles.sizeSelectorContainer}>
                  <Select
                    placeholder='Select your size'
                    onSelect={(sizeOption) => setTshirtSize({ sizeOption, prices })}
                    isValid={productInfo.validation.isSizeSelected}
                    isShowErrors={isShowErrors}
                    errorMessage='Please select your size'
                    value={productInfo.selectedSizeOption}
                    options={productInfo.sizesOptions}
                  />
                </div>
                <div className={styles.sizeChartLink} onClick={() => setIsSizingChartOpened(!isSizingChartOpened)}>
                  Sizing Chart{' >'}
                </div>
              </div>
              {isSizingChartOpened && (
                <div className={isSizingChartOpened ? styles.bodyOverlay : ''} onClick={handleChartOutside}>
                  <div className={styles.popUpContainer} ref={chartRef}>
                    <div className={isSizingChartOpened ? styles.closeIcon : ''} onClick={() => setIsSizingChartOpened(false)}>
                      <img src={closeIcon} alt='more information' />
                    </div>
                    <img className={styles.sizeChart} src={chartImage} alt='size chart' />
                  </div>
                </div>
              )}

              <div className={styles.buttonContainer}>
                <Button value='Buy Now' customAction={handleBuyProductClicked} loading={productInfo.UIState.isLoading} />
              </div>
              <div className={styles.amazonBlock}>
                <img className={styles.amazonIcon} src={amazonIcon} alt='' />
                Each purchase will be entered for a chance to win a $100 Amazon Gift Card
              </div>

              <div className={styles.productTerms}>
                <div className={styles.productTermsTitle}>Description</div>
                {productInfo?.selectedProductPrice?.description}
              </div>

              <div className={styles.moreSection} onClick={() => handleShowMoreClicked(!isMoreInfoOpened)}>
                More Information
                <img className={styles.moreIcon} src={plusIcon} alt='more information' />
              </div>

              {isMoreInfoOpened && (
                <div className={isMoreInfoOpened ? styles.bodyOverlay : ''} onClick={handleMoreOutside}>
                  <div className={styles.popUpContainer} ref={moreRef}>
                    <div className={isMoreInfoOpened ? styles.closeIcon : ''} onClick={() => handleShowMoreClicked(false)}>
                      <img src={closeIcon} alt='more information' />
                    </div>
                    <div className={styles.moreBlock}>
                      <div className={styles.moreContent}>
                        <p className={styles.moreTitle}>Specifications</p>
                        <p className={styles.moreText}>
                          Made with a durable, cotton material, this t-shirt is meant to withstand the test of time. And thanks to the breathability & flexibility, it can even be
                          worn as an athletic wear or even to bed!
                        </p>
                        <p className={styles.moreText}>
                          -Breathable Cotton <br />
                          -Machine Washable <br />
                          -Crew neck <br />
                          -Relaxed fit <br />
                          -Graphic print at front <br />
                          -All 100% ring spun cotton <br />
                          -All seams are double stitched and reinforced
                        </p>
                        <p className={styles.moreTitle2}>Shipping and Returns</p>
                        <p className={styles.moreText}>
                          Enjoy our <span className={styles.moreTextBold}>premium shipping</span> on all orders! Time of delivery: <span className={styles.moreTextBold}>5-7</span>{' '}
                          business days.
                        </p>
                        <p className={styles.moreText}>Due to the ongoing pandemic, some orders may face delays of up to 1-2 weeks.</p>
                        <p className={styles.moreText}>We will get your order out to you as fast as possible. Stay safe and strong.</p>
                        <p className={styles.moreText}>
                          For returns and exchanges, our customer support team is available 24 hours a day, 7 days a week. Contact us whenever you wish and we will be more than
                          happy to help return or exchange your shirts! Please be advised that some designs do sell out, and we will try our very best to cater to your needs.
                        </p>
                        <p className={styles.moreText}>
                          We are so confident you will love your shirts, but if there are any issues please feel free to contact our customer service at{' '}
                          <span>
                            <a className={styles.moreEmail} href={`mailto:${supportEmail}`}>
                              {supportEmail}
                            </a>
                          </span>
                          . We take your happiness very seriously and will not stop until you are absolutely thrilled.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.relatedSection}>
            <div className={styles.relatedSectionTitle}>Related Items</div>

            {/* PRODUCTS SLIDER START */}
            <BrowserView>
              <div className={styles.collectionSlider}>
                <Slider {...collectionSliderSettings}>
                  {collectionProducts.map(product => (
                    <Link
                      onClick={() => setSelectedProductId(product.id)}
                      to={`/products/${(product.hasCustomSeo && product.seoUrl) || product.productId}`}
                      className={styles.collectionItem}
                      rel='noopener noreferrer'
                      key={product.id}
                    >
                      <div className={styles.productCard}>
                        <ProgressiveImage src={product.previewImageUrl}>
                          {src => <img src={src} alt={product.name} className={styles.productImg} />}
                        </ProgressiveImage>

                        <div className={styles.productInfo}>
                          <div>{collectionName}</div>
                        </div>

                        <div className={styles.productNameSlider}>{product.productDisplayName}</div>
                        <div className={styles.productNameSlider}>${convertToUSD(product.price)}</div>
                        <div className={styles.productMemberPrice}>${convertToUSD(product.memberPrice)} (member price)</div>
                      </div>
                    </Link>
                  ))}
                </Slider>
              </div>
            </BrowserView>
            {/* -----------------------------PRODUCTS SLIDER END------------------------------------ */}

            {/* -----------------------------PRODUCTS MOBILE SLIDER START---------------------------------- */}
            <MobileView>
              <div className={styles.collectionSliderMobile}>
                <Slider {...mobileCollectionSliderSettings}>
                  {collectionProducts.slice(0, productToShowMobile).map(product => (
                    <Link
                      className={styles.collectionItem}
                      rel='noopener noreferrer'
                      to={`/products/${(product.hasCustomSeo && product.seoUrl) || product.productId}`}
                      key={product.id}
                      onClick={() => setSelectedProductId(product.id)}
                    >
                      <div className={styles.productCard}>
                        <ProgressiveImage src={product.imageUrl}>
                          {src => <img src={src} alt={product.name} className={styles.productImg} />}
                        </ProgressiveImage>

                        <div className={styles.productInfo}>
                          <div>{collectionName}</div>
                        </div>

                        <div className={styles.productNameSlider}>{product.productDisplayName}</div>
                        <div className={styles.productNameSlider}>${convertToUSD(product.price)}</div>
                        <div className={styles.productMemberPrice}>${convertToUSD(product.memberPrice)} (member price)</div>
                      </div>
                    </Link>
                  ))}
                </Slider>
              </div>
            </MobileView>
            {/* -----------------------------PRODUCTS MOBILE SLIDER END------------------------------------ */}
          </div>

          <div className={styles.pointsSection}>
            <div className={styles.pointsMobileTitle}>{campaignName} Promises</div>
            <div className={styles.pointsBlock}>
              <div className={styles.pointsItem}>
                <div className={styles.pointsHeader}>
                  <img className={styles.pointsIcon} src={guaranteeIcon} alt='-' />
                  Best Price Guarantee
                </div>
                <p className={styles.pointsText}>Our best price guarantee is possible through our DTG machines + a small but fierce in-house group of designers and printers.</p>
              </div>
              <div className={styles.pointsItem}>
                <div className={styles.pointsHeader}>
                  <img className={styles.pointsIcon} src={priceIcon} alt='-' />
                  Premium Shipping
                </div>
                <p className={styles.pointsText}>Shipping should be fast and easy. We use premium shipping to make sure your merch gets to you safe and quickly.</p>
              </div>
              <div className={styles.pointsItem}>
                <div className={styles.pointsHeader}>
                  <img className={styles.pointsIcon} src={madeIcon} alt='-' />
                  Made In USA
                </div>
                <p className={styles.pointsText}>
                  All of our products are made in the USA. We want to support as much US based companies as possible and we are proud to say that 100% of our goods are from North
                  Carolina.
                </p>
              </div>
              <div className={styles.pointsItem}>
                <div className={styles.pointsHeader}>
                  <img className={styles.pointsIcon} src={usaIcon} alt='-' />
                  Quality Checked
                </div>
                <p className={styles.pointsText}>We make sure to double check all orders to ensure that the designs are printed perfectly on your merchandise.</p>
              </div>
            </div>
          </div>
        </div>

        {/* -----------------------------MOBILE CUSTOMERS SLIDER START---------------------------------- */}
        <div className={styles.customersSlider}>
          <div className={styles.customersSliderTitle}>Our happy customers</div>
          <Slider {...mobileCustomerSliderSettingsForReviews}>
            <div className={styles.customersItemWrapper}>
              <div className={styles.customersItem}>
                <div className={styles.customersData}>
                  Johanthan D.
                  <div className={styles.customersVerify}>
                    <img className={styles.customersVerifyIcon} src={redCheck} alt='-' />
                    Verified Buyer
                  </div>
                </div>
                <div className={styles.customersWherefrom}>Dallas, Texas</div>
                <img className={styles.customersRating} src={rating} alt='-' />
                <p className={styles.customersTitle}>Wifey approved</p>
                <p className={styles.customersReview}>
                  I’m very glad I found {campaignName}. They are amazing and they are the cheapest option out there. Very happy with my purchases going to order more for my wife
                  and kids.
                </p>
              </div>
            </div>

            <div className={styles.customersItemWrapper}>
              <div className={styles.customersItem}>
                <div className={styles.customersData}>
                  Nicole S.
                  <div className={styles.customersVerify}>
                    <img className={styles.customersVerifyIcon} src={redCheck} alt='-' />
                    Verified Buyer
                  </div>
                </div>
                <div className={styles.customersWherefrom}>Ozark, Missouri</div>
                <img className={styles.customersRating} src={rating} alt='-' />
                <p className={styles.customersTitle}>Just go for it!</p>
                <p className={styles.customersReview}>Love the t-shirt! Love {campaignName}! The company is very responsive to emails. Love Love!</p>
              </div>
            </div>

            <div className={styles.customersItemWrapper}>
              <div className={styles.customersItem}>
                <div className={styles.customersData}>
                  Taylor C.
                  <div className={styles.customersVerify}>
                    <img className={styles.customersVerifyIcon} src={redCheck} alt='-' />
                    Verified Buyer
                  </div>
                </div>
                <div className={styles.customersWherefrom}>Palmdale, California</div>
                <img className={styles.customersRating} src={rating} alt='-' />
                <p className={styles.customersTitle}>I’m glad I bought it.</p>
                <p className={styles.customersReview}>T-Shirt is so soft and it fits so much better than expectation! Will order again.</p>
              </div>
            </div>
          </Slider>
        </div>
        {/* -----------------------------MOBILE CUSTOMERS SLIDER END------------------------------------ */}

        <div className={styles.customersSection}>
          <div>Our happy customers</div>
          <div className={styles.customersList}>
            <div className={styles.customersItem}>
              <div className={styles.customersData}>
                Johanthan D.
                <div className={styles.customersVerify}>
                  <img className={styles.customersVerifyIcon} src={redCheck} alt='-' />
                  Verified Buyer
                </div>
              </div>
              <div className={styles.customersWherefrom}>Dallas, Texas</div>
              <img className={styles.customersRating} src={rating} alt='-' />
              <p className={styles.customersTitle}>Wifey approved</p>
              <p className={styles.customersReview}>
                I’m very glad I found {campaignName}. They are amazing and they are the cheapest option out there. Very happy with my purchases going to order more for my wife and
                kids.
              </p>
            </div>
            <div className={styles.customersItem}>
              <div className={styles.customersData}>
                Nicole S.
                <div className={styles.customersVerify}>
                  <img className={styles.customersVerifyIcon} src={redCheck} alt='-' />
                  Verified Buyer
                </div>
              </div>
              <div className={styles.customersWherefrom}>Ozark, Missouri</div>
              <img className={styles.customersRating} src={rating} alt='-' />
              <p className={styles.customersTitle}>Just go for it!</p>
              <p className={styles.customersReview}>Love the t-shirt! Love {campaignName}! The company is very responsive to emails. Love Love!</p>
            </div>
            <div className={styles.customersItem}>
              <div className={styles.customersData}>
                Taylor C.
                <div className={styles.customersVerify}>
                  <img className={styles.customersVerifyIcon} src={redCheck} alt='-' />
                  Verified Buyer
                </div>
              </div>
              <div className={styles.customersWherefrom}>Palmdale, California</div>
              <img className={styles.customersRating} src={rating} alt='-' />
              <p className={styles.customersTitle}>I’m glad I bought it.</p>
              <p className={styles.customersReview}>T-Shirt is so soft and it fits so much better than expectation! Will order again.</p>
            </div>
          </div>
        </div>

        <div className={styles.foollowUsContainer}>
          <FollowUs />
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer />
        <Notification message={notification} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loginState: loginSelectors.selectLoginState(state),
  menu: selectMenu(state),
  productInfo: selectors.selectProductInfo(state),
  prices: selectPrices(state),
  products: selectProducts(state)
})

const mapDispatchToProps = {
  setPageAfterLogin: loginActions.setPageAfterLogin,
  setProductPrice: actionCreators.setProductPrice,
  setTshirtSize: actionCreators.setTshirtSize,
  addProductToCartAsync: actions.addProductToCart,
  setMenuCollection
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage)
